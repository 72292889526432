import React, { useEffect } from 'react'
import { sendMail } from './API/workflows'
import { Box, Button, Typography } from '@mui/material'
import newTheme from './newTheme'
import { centered_container, end_buttons, text_space, title_style } from './Utils/defaultStyles'
import { isValidJson } from './Utils/functions'
import { logout } from './API/login'

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
)

const css = {
  container: {
    background: newTheme.palette.background.sticker,
    padding: 2,
    margin: '12px auto',
    borderRadius: 2,
    maxWidth: 600,
  }
}

function getLocalStorageAsObject() {
  let localStorageObject = {};

  for (let i = 0; i < localStorage.length; i++) {
    let key = localStorage.key(i);
    localStorageObject[key] = localStorage.getItem(key);
  }

  return localStorageObject;
}

function checkNeededLocalStorageObjects() {
  const neededObjects = ["branch", "enterprise", "current_user", "login", "token", "account"]
  // Check if any item is undefined or missing
  for (let item of neededObjects) {
    if (!localStorage.getItem(item) || localStorage.getItem(item) === "undefined") {
      console.error(`Missing item in localStorage: ${item}`)
      return false
    }
  }
}

function ErrorBoundary({ error, info }) {

  const localCurrentUser = localStorage.getItem("current_user")
  const currentUser = !!localCurrentUser && isValidJson(localCurrentUser) ? JSON.parse(localCurrentUser) : null

  checkNeededLocalStorageObjects()

  useEffect(() => {
    const userName = currentUser?.name || "Usuario no logueado"
    const userId = currentUser?.id || "Usuario no logueado"

    console.log(error?.stack)

    const isChunkError = error?.stack?.includes('ChunkLoadError') || error?.stack?.includes('CSS')
    if (isChunkError) return handleBack()

    const body = {
      subject: `Error encontrado por: ${userName} (${userId}) - ${window.location.pathname}`,
      content: `
        ${error?.stack} ${info.componentStack} | 
        LocalStorage: ${JSON.stringify(getLocalStorageAsObject())}
      `,
      emails: ['mati.eynaudi@gmail.com'],
    }

    if (isLocalhost) return console.log(body)
    sendMail(body)
  }, [])

  async function handleBack() {

    const isLocalStorageOK = checkNeededLocalStorageObjects()

    // Unregister service workers
    console.log("Actualizando")
    const registrations = await navigator.serviceWorker.getRegistrations()
    console.log("Deleting old service workers...")
    for (let registration of registrations) {
      console.log(registration)
      await registration.unregister()
    }

    // Remove all the caches
    console.log("Deleting all cache...")
    const cacheNames = await caches.keys()
    for (let cacheName of cacheNames) {
      console.log(cacheName)
      await caches.delete(cacheName)
    }

    // Register the new service worker
    console.log("registering new service worker...")
    await navigator.serviceWorker.register("/sw.js")

    console.log("Done!")
    window.location.href = window.location.origin

    if (!isLocalStorageOK) {
      // Clean localStorage and logout
      logout()
    }
  }


  return (
    <Box sx={css.container}>
      <Typography variant="h1" sx={title_style} color="info.main" >Oops!</Typography>
      <Box sx={centered_container}>
        <img src="/error/error_tazki.png" alt="404" width={'100%'} style={{ maxWidth: 300, borderRadius: 2, margin: '12px 0' }} />
      </Box>
      <Typography variant="subtitle1" sx={text_space}>
        ¡Buenas noticias! Hemos detectado y prevenido un problema antes de que sucediera.
        Estamos trabajando para asegurarnos de que todo funcione perfectamente.
        Por favor, haz clic en 'Volver' para continuar.
      </Typography>
      <Box sx={end_buttons}>
        <Button variant="contained" color='success' onClick={handleBack}>
          Volver
        </Button>
      </Box>
    </Box>
  )
}

export default ErrorBoundary